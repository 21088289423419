<template>
    <v-card flat outlined>
        <v-card-text style="background-color:#eeeeee;">
            <v-row>
                <v-col class="text-center mt-12 mb-12" style="font-size:24px; font-weight:600;">결제수단을 등록합니다</v-col>
            </v-row>
        </v-card-text>        
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import payApi from '@/api/pay'

export default {
    name: 'pay_forward_pg',
    components: {
//    TileStats, TileStaffs
    },
    data: () => ({
    }),
    computed: {
        ...mapGetters({
            companyPay: 'pay/getCompanyPay',
        }), 
    },
    created () { 
    },
    mounted () { 
        var ctx = this
        setTimeout(function() { 
            ctx.doSubmit()
        }, 500)
    },
    methods: {
        goNext() { 
            this.$router.push('/pay/status')
        },
        doSubmit () { 
            var bktrTid = this.$route.params.bktr_tid

            var va = this
            payApi.changeBillKey({
                bktr_tid: bktrTid,
            }, () => { 
                alert("결제수단이 등록되었습니다")
                va.goNext()
            }, (err) => { 
                alert("결제수단 등록을 실패 했습니다")
            }, va)
        },
    }
}
</script>
<style>
</style>


